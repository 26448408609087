import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import Layout from '../components/Layout';
import HeaderSeo from '../components/HeaderSeo';

import TopHeader from '../views/shared/TopHeaderV2';

import ActivityCard from '../views/shared/ActivityCard';

import colors from '../assets/colors';

const Container = styled.div`
  font-family: 'Open Sans', sans-serif;
  padding: 26px 36px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 894px) {
    padding: 17px 34px;
  }
`;
const H1 = styled.h1`
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 44px;
`;
const SubTitle = styled.h4`
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
`;
const Colored = styled.a`
  color: ${colors.primary};
  text-decoration: none;
`;
const H3 = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 19.5px;
  line-height: 27px;
  font-family: 'Open Sans', sans-serif;
`;

const TermsConditions = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const termsConst = {
    title: <div>Platform Terms & Conditions of Service</div>,
    subTitle: (
      <SubTitle>
        The platform of GoGet.my and GoGet Recruit (“Platform”) provides a means
        to enable an individual or persons (“posters”) who seek delivery to
        certain destinations and real time fulfillment of an errand or errands
        at a specific location to be matched and flexible hiring with persons
        with experience, capacity and/or ability to fulfill these tasks
        ("GoGetters") or potential job applicants with the requirements of the
        posters' job posting. The Platform also offers information and a method
        to connect GoGetters, job applicants and posters with each other but
        does not provide delivery and manpower services or acts in any manner as
        a delivery carrier or manpower supplier. That being the case, the
        Platform allows for posters and GoGetters or job applicants to switch
        roles from time to time during the fulfillment of a task or job listing.
        <br />
        <br />
        The services as mentioned above shall collectively be defined as the
        "services". These terms and conditions shall govern your use of and
        participation as a user, poster, job applicant or GoGetter in the
        Platform. “Personal Data” refers to information about an identifiable
        individual, but excludes the designation, business address and/or
        telephone number of an individual who is an employee of an organisation.
      </SubTitle>
    )
  };
  return (
    <>
      <HeaderSeo
        title="GoGet | Terms & Conditions"
        pathname={props.location.pathname}
      />
      <Layout>
        <TopHeader
          isTerms
          backgroundMobileHeight="unset"
          title={termsConst.title}
          description={termsConst.subTitle}
          h4="Terms & Conditions"
        />
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            position: 'relative',
            top: '-5rem'
          }}
        >
          <Container>
            <H1>General</H1>
            <>
              1. Below are the terms and conditions of the Platform, a community
              marketplace concept application and platform that is owned and
              operated by Eternal Meteor Sdn. Bhd. (Company no. 1026031-T),
              incorporated in Malaysia. By your sign up and/or usage of Platform
              services, website and products, you are legally and automatically
              bound to these terms and conditions without conditions.
              <br />
              <br />
              2. By virtue of your sign up and/or usage, you as the poster, user
              and GoGetter (“you”) accept all terms and conditions of the
              Platform. This means that you affirm your understanding of the
              terms and conditions of Platform and are within full capacity to
              enter into a transaction with Platform and Eternal Meteor Sdn. Bhd
              for its services and/or products.
              <br />
              <br />
              3. Platform does not provide direct carrier, transportation or
              delivery services and they do not employ people to carry out any
              task for any user or poster in the Platform. It is up to the
              GoGetter to decide whether or not to offer his, her or their
              services to a poster on the Platform. Similarly, it is up to the
              poster to decide whether or not to accept a service offer from any
              GoGetter over its poster or post, contacted through the Platform.
              Any decision to offer or accept delivery or task fulfillment
              services once such poster that is matched through the Platform is
              a mutual decision made directly between the poster and GoGetter
              without liability or responsibility to Platform and Eternal Meteor
              Sdn. Bhd. Additionally, Platform and Eternal Meteor Sdn. Bhd.
              shall not be liable for the actions and transactions of any user,
              the poster and GoGetter and must be treated as absolutely harmless
              and blameless at all times. Please read these terms and conditions
              carefully before signing up and using the services and Platform.
              If you do not agree to be bound by these terms and conditions,
              please do not use or access the Platform. Furthermore, you may
              elect to read these terms and conditions again at any time after
              signing up and/or using our services.
              <br />
              <br />
              4. These terms and conditions, and its updates are subject to the
              discretion of the Platform and Eternal Meteor Sdn. Bhd. management
              team at any time. Any notice and updates thereof shall be posted
              on the Platform as and when needed. You are bound by all amended
              terms or updates of these terms and conditions, which become
              effective upon their posting on the Platform.
            </>
            <br />
            <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px'
          }}
        >
          <Container>
            <H1>Purpose of Scope and Operations</H1>
            <>
              The purpose of the Platform is to be an online app/web-based
              communications platform that operates as an online marketplace for
              errands, tasks, deliveries and flexible job hiring. This is done
              by connecting potential clients (“posters”) with people
              (“GoGetters”) or job applicants who can accomplish or meet the
              requirements of any of the job types listed inside.
            </>
            <br /> <br />
            <>
              The categories may expand in the future, depending on the need and
              requirements of the poster.
              <br /> <br />
              The Platform does not undertake these jobs or tasks nor does it
              create employment relationships with individuals to complete jobs.
              Additionally, Platform does not supervise, direct, manage or
              control the jobs or tasks a GoGetter accepts to which the GoGetter
              and poster acknowledges.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>User Vetting</H1>
            <>
              Users of Platform are subject to an extensive vetting process when
              they register to be a GoGetter, job applicant and/or poster during
              their use of the Platform including but not limited to a
              verification of identity and a comprehensive criminal background
              check. Those seeking to register to be a GoGetter and poster
              hereby consent to Platform conducting these background checks as
              often as necessary in compliance with Malaysia's state and federal
              laws or the relevant country of the office or group office of the
              Platform at the material time (if it is not in Malaysia). Once
              verified, aspiring GoGetters must attend a training session and
              complete a multiple choice test to validate their understanding of
              what is taught and to ensure their English competency skills are
              acceptable.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Contract Between GoGetter and Poster</H1>
            <>
              By using Platform app and website you acknowledge that a service
              contract is formed when you agree to the terms of a job or task
              with another platform user. As a poster you acknowledge that you:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. offer an agreed financial sum as payment to the GoGetter for
                a job that you have assigned which may include reimbursement for
                costs taken-on by the GoGetter;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. are not creating an employment situation between the GoGetter
                and the platform and Eternal Meteor Sdn. Bhd. As a GoGetter you
                acknowledge that you will comply strictly with the poster's
                instructions in exchange for payment that you have agreed to,
                understanding that you will have to assume responsibility of
                your own costs and utilize your own method of transport in
                pursuit of completing the job.
              </div>
              <br />
              Payments shall be made and processed through Platform Credits as
              per the terms and conditions under 'Platform Fees'. For certain
              jobs or tasks, cash on-hand or online banking may be acceptable
              depending on the mutual agreement of the parties. Both parties
              agree to notify Platform of any disputes arising from the job at
              least (30) days before initiating any proceedings or action
              against the other party, utilizing Platform as an intermediary and
              mediator to resolve aforementioned disputes.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Acceptable use Policy</H1>
            <>
              Users of GoGet.my may not in any way utilize the online platform
              for any activity that:
              <br /> <br />
              1. Violates any state and federal law or government regulation in
              Malaysia and/or that of the country where the delivery, job, task
              or service is to take place or be conducted.
              <br /> <br />
              2. Violates any rule or regulation stating the acceptable code of
              conduct enforced by Platform on its GoGetters.
              <br /> <br />
              3. Violates any rule or regulation by Visa, Mastercard or any
              Credit Card Network that is utilized by Platform on its platform
              for payment use.
              <br /> <br />
              4. Violates any rules or regulations dictated by our payment
              service provider.
              <br /> <br />
              5. Is fraudulent, deceptive, unfair or predatory.
              <br /> <br />
              6. Causes or threatens reputational damage to Platform or any
              Credit Card Network.
              <br /> <br />
              7. Involves any of the business categories which are
              age-restricted such as alcoholic beverage (exceptions apply if age
              is verified).
              <br /> <br />
              8. Results in or creates significant risk of chargebacks,
              penalties, damages or other harm or liability.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Termination and Suspension</H1>
            <>
              Platform reserves the right to terminate or suspend your right to
              use the online platform in the event we believe that you have
              breached this Agreement by providing you with a written or email
              notice of such breach/termination/suspension that will be
              effective immediately upon delivery. If your right to use Platform
              is terminated or suspended, this agreement will remain enforceable
              against you. You may terminate this agreement at any time by
              ceasing all use of the Platform. All sections which by their
              nature should survive the expiration or termination of this
              agreement shall continue in full force and effect subsequent to
              and notwithstanding the expiration or termination of this
              agreement.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Eligibility and Representations</H1>
            <>
              1. Our services at Platform and through the Platform are available
              only to, and may only be used by individuals of 18 years of age
              and above. By signing up and/or participating as a poster or
              GoGetter, you represent and warrant that you meet this
              requirement. You also represent and warrant that you have the
              right, authority and full capacity to be a poster, job applicant
              or GoGetter on the Platform and comply with all their terms,
              conditions and updates. Furthermore, you represent and warrant
              that you have the right, authority and full capacity to sign up
              for and utilize any Platform education lessons, programmes,
              rewards and benefits offered on the Platform (“benefits”) as a
              GoGetter such as Pod Savings, SOSCO self-employment social
              security scheme and insurance and more of your choice at any time
              and cancel any participation or usage involved after the event.
              These benefits shall apply accordingly for you as a poster or job
              applicant.
              <br />
              <br />
              2. Your information is any information you provide, publish or
              display ("publish") to the Platform or send to other posters in
              the registration or in any public message area (including, but not
              limited to the feedback section) or through any email feature
              ("your information"). Your information will be stored on computers
              and in our business server. However, any personal information
              provided by you shall be collected, stored and retained by Eternal
              Meteor Sdn. Bhd. in accordance of the provisions of the Personal
              Data Protection Act 2010, a copy of the act is available for view
              at the library records of the Bar Council of Malaysia or online.
              By your sign up and/or usage of the services, you consent to us
              using your information to create a poster account or GoGetter
              account that will allow you to participate in the services.
              <br />
              <br />
              3. By signing up in the Platform and/or using the services, you
              agree that we may obtain personal information including and
              without limitation to your identification, contact details or
              points, driving record, references and/or credit information. You
              hereby authorize Platform and Eternal Meteor Sdn. Bhd. to perform
              necessary background checks for its purposes, and further agrees
              to provide any necessary authorization to facilitate our access to
              necessary information for the purposes of the business of
              Platform.
              <br />
              <br />
              4. You are solely responsible for your information and your
              interactions with other people in the public, and we act only as a
              passive conduit for your online posting of your information. When
              you use the Platform, you agree to provide accurate, current and
              complete information as prompted by our registration form and to
              maintain and timely update your information to keep it accurate,
              current and complete at all times during the term of the
              agreement. You also agree that Eternal Meteor Sdn. Bhd,
              participants in the Platform and the public may rely on your
              information as accurate, current and complete. You acknowledge
              that if your information is untrue, inaccurate, not current or
              incomplete in any respect, we have the right to terminate your use
              of the services without notice to you.
              <br />
              <br />
              5. You are allowed to one Platform account on the Platform or
              authority over one Platform account thereon. You are also
              responsible for maintaining the confidentiality of any password
              provided by you or Platform for accessing the services.
              Additionally, you are solely and fully responsible for all
              activities that occur under your password or account.
              <br />
              <br />
              6. Platform represents that it has no control over the use of its
              GoGetter or poster's account at all times and expressly disclaims
              any or all liability derived therefrom. Should you suspect that
              any unauthorized party may be using your password or account or
              you suspect any other breach of security, you must contact the
              Platform administrators immediately. Platform and Eternal Meteor
              Sdn. Bhd. reserves the right to take any legal action against you
              for misrepresentation for any loss or damage suffered as a result
              of any misuse or abuse of your account.
              <br />
              <br />
              7. Platform tries its best to protect the privacy of data exchange
              in the Platform from misrepresentation, third party abuse or
              internal hacking but shall not be liable or claims no admission to
              liability in the event of any damage or loss occurs to the poster
              or GoGetter. All data exchange outside the Platform between the
              poster and GoGetter is strictly at the risk of the poster and
              GoGetter.
              <br />
              <br />
              8. By using the Platform platform and the services, you as the
              poster, represents, warrants and agrees that:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. The poster had made all necessary checks before posting tasks
                on the Platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. The poster will obey all local laws related to the matters
                set forth herein, and will be solely responsible for any
                violations of such local laws including being involved in any
                scam, criminal, drug or unlawful activities;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. The poster is solely responsible and shall indemnify all
                claimants including Eternal Meteor Sdn. Bhd. and its
                representatives against any misconduct, abuse, misrepresentation
                or wrongful allegation regarding or involving Platform, the
                Platform in the posting of the poster up to the delivery of
                tasks mentioned in the poster for the poster at all times;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. The poster shall not treat, name or hold Platform or Eternal
                Meteor Sdn. Bhd. responsible for any misconduct, abuse,
                misrepresentation or wrongful allegation regarding or involving
                Platform, the Platform by the GoGetter;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. The poster understands that Platform is just a platform to
                connect the poster and GoGetter and there shall not be any
                employer-employee relationship assumed or formed between the
                poster and Platform and/or the GoGetter and Eternal Meteor Sdn.
                Bhd.; and
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. The poster makes and will make all necessary declarations on
                the Platform or if asked from time to time by Eternal Meteor
                Sdn. Bhd. about restricted activities.
              </div>
              <br />
              <br />
              9. By using the Platform and the services, you as the GoGetter
              however represents, warrants and agrees that:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. You have made and will make all necessary declarations on the
                Platform or if asked from time to time by Eternal Meteor Sdn.
                Bhd. about your background restricted activities. If you are
                legally impaired by reason that you do not have the correct
                permit, licence and/or allowance to accept any poster on the
                Platform before or after you become a GoGetter, it is your legal
                duty and responsibility to inform the Platform and Eternal
                Meteor Sdn. Bhd. fully and accurately of the case. You agree to
                hold the Platform and Eternal Meteor Sdn. Bhd. absolutely
                harmless and not responsible if you are an unauthorized person
                in Malaysia and/or for the purposes of Platform activities and
                posters;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. You must possess a valid driver's license for the purposes of
                Malaysia and is authorized to operate a third party insured
                motor vehicle (“vehicle”) and has all appropriate licenses,
                approvals and authority to provide delivery to third parties in
                all jurisdictions in which such GoGetter uses the services. If
                you are a foreigner, the GoGetter must possess a driver's
                license which is valid for the purposes of possessing and
                driving a vehicle in Malaysia. Alternatively, you must possess a
                valid license of the appropriate country where the delivery,
                job, task or service is to take place or be conducted;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. You own or have the legal right to operate the vehicle when
                making delivery tasks as per the poster and that such vehicle is
                in good operating condition including meeting the Malaysian
                vehicle safety standards in accordance with the Malaysian law.
                The same rule applies to the country where the delivery, job,
                task or service is to take place or be conducted;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. Your vehicle has a valid policy copy of third-party liability
                insurance for the operation of such GoGetter's vehicle to cover
                any anticipated losses related to such GoGetter's provision of
                deliveries to posters;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. You are and will be responsible for any and all damages, loss
                and harm which results from the operation of your vehicle
                including, but not limited to, personal injuries, death and
                property damages and absolutely indemnifies Eternal Meteor Sdn.
                Bhd. if damages, loss and harm occurs to it and the Platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. You understand that the Platform is just a platform to
                connect you with posters. You agree that you are an independent
                contractor, not employees of the platform and Eternal Meteor
                Sdn. Bhd. You shall ensure that there is and will be no
                presumption or assumption of any employer-employee relationship
                assumed or formed with the Platform and/or Eternal Meteor Sdn.
                Bhd. by your words, actions, conduct and suggestions;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                g. You are and will be solely responsible for its own work
                permit, health and safety in order to accept, carry out and
                complete properly the delivery of tasks accepted in the poster;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                h. You will obey all local laws related to the matters set forth
                herein, and will be solely responsible for any violations of
                such local laws. If you are called by Eternal Meteor Sdn. Bhd.
                for a meeting or to answer an investigation in connection with
                your compliance, you shall provide your full co-operation
                towards the same;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                i. You are solely responsible and shall indemnify all claimants
                including Eternal Meteor Sdn. Bhd. and its representatives
                against any misconduct, abuse, misrepresentation or wrongful
                allegation regarding or involving Platform, the Platform in the
                discharge of its delivery or the services for the poster at all
                times;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                j. You do not and shall not treat, name, defame or hold Platform
                or Eternal Meteor Sdn. Bhd. responsible or contributable for any
                misconduct, abuse, misrepresentation or wrongful allegation
                regarding or involving Platform, the Platform and Eternal Meteor
                Sdn. Bhd. whether publicly, online or towards third parties. You
                do not and will make any outdated, inaccurate, untrue, false and
                defamatory statements about Platform, the Platform and Eternal
                Meteor Sdn. Bhd. at all times.
              </div>
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Independent Contractors</H1>
            <>
              1. All GoGetters are independent contractors of Eternal Meteor
              Sdn. Bhd. only and have declared that there is no agreement
              whatsoever to enter into any employment relationship with the
              company.
              <br /> <br />
              2. All GoGetters understand that Eternal Meteor Sdn. Bhd. has no
              limit and/or control over any or all of the GoGetters in any
              manner. Each and all GoGetters have absolute freedom of their time
              and conduct over how they execute and complete each delivery and
              task under a poster in the Platform. All GoGetters accept and
              agree that the platform and Eternal Meteor Sdn. Bhd. are not the
              directing mind and controllers of all GoGetters and their work,
              performance and duties. All GoGetters legally assume their own
              risks and when they:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. register with the platform as a GoGetter;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. enter and use the platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. accept a poster and task in the platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. deal with any person, third party and authority connected
                with the poster;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. carry out and complete any delivery and task from the poster;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. receive payment and fees as a result of each completed
                delivery task.
              </div>
              <br />
              Eternal Meteor Sdn. Bhd. warrants at all times that the Platform
              presents an opportunity where any GoGetter can earn more than one
              source, a side or second income. It is not to be seen as a
              full-time, part-time or contract source of employment and/or the
              only or major source of income for any GoGetter. Further, this
              representation goes to any third-party advertisement(s) posted by
              a poster. Similarly, Eternal Meteor Sdn. Bhd. is not responsible
              for the way and manner of how a GoGetter chooses to earn his, her
              or their income in life. It is the platform's public policy and
              encouragement that all GoGetters should be free to look for as
              much work opportunities as possible and to benefit as many income
              source providers as possible. For these reasons, there is no hours
              and time or date fixed by the platform and Eternal Meteor Sdn.
              Bhd. involving a poster and task. Furthermore, any and all
              payments from the Platform and Eternal Meteor Sdn. Bhd shall be
              taken that it or they do not constitute the whole and/or majority
              income of a or all GoGetters.
              <br />
              <br />
              3. A GoGetter's relationship through the Platform and with Eternal
              Meteor Sdn. Bhd. shall not be assumed and treated as a taxable
              employee and/or partner of Eternal Meteor Sdn. Bhd. All GoGetters
              shall be responsible for all taxes and government payments in
              respect of fees and monies received as well as his, her or their
              own social security contributions. Any payment(s) towards the
              authorities for the GoGetter(s) has been and is purely based on
              the GoGetter's free will and elected choice on the platform.
              Further, the GoGetter undertakes to disclaim liability towards as
              well as absorb and compensate Eternal Meteor Sdn. Bhd. in full on
              demand for any liability suffered with any third-parties and any
              of the authorities or other relevant government department that
              incorrectly treats the GoGetter as a taxable employee and/or
              partner.
              <br />
              <br />
              4.GoGetters shall be responsible for all tools, materials or
              equipment used, regardless of whether they are personal, provided
              for by Eternal Meteor Sdn. Bhd. or a third party in carrying out
              and completing the tasks of the posters in the Platform. Eternal
              Meteor Sdn. Bhd. provide such tools, materials or equipment, if
              any, for the objective of helping GoGetters carry out their tasks
              and not for employment purposes.
              <br />
              <br />
              5. GoGetters understand that they are free to enter into any
              employment relationship with any person/authority/body and the
              service and tasks undertaken by GoGetters shall not be considered
              a full time and/or part time job that falls within the employment
              nature.
              <br />
              <br />
              6. By being a GoGetter, Eternal Meteor Sdn. Bhd. is entitled to
              submit any necessary support, information and documents collected
              to the police, authorities or any other government department
              without needing to inform the GoGetter or a court order for the
              purposes of resolving any issue faced in respect to a GoGetter.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Platform Fees</H1>
            <u>GoGet.My</u>
            <br />
            <>
              1. As a poster, there may be a fee for a delivery, job, task or
              service that a GoGetter has carried out and completed for you or
              an advertisement that you post on the Platform. The GoGetter or
              Eternal Meteor Sdn. Bhd. do not receive any compensation or
              consideration other than the amount (if any) of this fee over this
              delivery, job, task, service or advertisement unless it is with
              the mutual agreement of the poster. That being said, this fee is
              not to be considered as a fixed, substantial and/or main income
              source for a GoGetter at any time. The fee does not create, imply
              or suggest an employment relationship between you and the GoGetter
              and any such relationship does not involve Platform and Eternal
              Meteor Sdn. Bhd. at any time.
              <br /> <br />
              2. A fee may be in cash or through other payment means offered on
              the Platform. It can also take on any amount from RM0 up, as
              specified by the poster on the Platform. It is the poster's sole
              responsibility, and not the responsibility of the Platform or
              Eternal Meteor Sdn. Bhd., to decide whether and how much to fix
              the poster's fee for the GoGetter. Any expenses incurred by the
              GoGetter for the purposes of carrying out or completing a
              delivery, job, task or service on behalf of the poster will have
              to be reimbursed in full by the poster to GoGetter once the same
              is completed.
              <br /> <br />
              3. When a post is posted on the Platform for a delivery, job, task
              or service, Eternal Meteor Sdn. Bhd. will deduct a fixed sum as
              the posting fees from the poster's prepaid credits on the Platform
              and hold the same as stakeholder until a successful match is made
              between the poster and a GoGetter (“Platform Credits”). Platform
              Credits can be purchased on the Platform using authorized credit
              cards, e-wallet and any other payment means offered on the
              Platform. All Credits are non-refundable regardless of:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. a poster's decision to terminate usage of the Platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. our decision to terminate a poster's usage;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. disruption caused to our services whether it was planned,
                accidental or intentional;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. death, bankruptcy or incapacity; and/or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. any other reason whatsoever.
              </div>
              <br />
              4. Once a post successfully matches a poster with a GoGetter on
              the Platform, Eternal Meteor Sdn. Bhd, takes a cut from the
              Platform Credits as their fees for the match (“matching fee”). A
              post is deemed successful once a poster approves of a GoGetter to
              perform the requirements of his, her or their post or once an
              automatic task approval on the Platform is activated. To clear all
              doubt, each matching fee and any fee imposed by Eternal Meteor
              Sdn. Bhd. shall be regarded as a service fee of the providers
              involved in relation to the task performed. The Platform and
              Eternal Meteor Sdn. Bhd. offer no matching guarantee for posts
              that are posted.
              <br /> <br />
              5. The Platform, at its sole discretion, may make available
              promotional offers with different features at any time and fees
              may be impacted if you are a preferred partner of the Platform
              and/or have a Platform preferred partner account with Eternal
              Meteor Sdn. Bhd. These promotional offers, unless made to you,
              shall have no bearing whatsoever on your offer or contract. The
              Platform may change its fees as we deem necessary for our
              business. We encourage you to check the Platform and its contents
              periodically if you are interested in keeping abreast of the rate
              of our fees.
              <br /> <br />
              6. In the event that a poster edits, changes, cancels, withdraws
              or terminates a delivery, job, task or service or a post on the
              Platform after a GoGetter has commenced with, carried out or
              completed the same, the poster must bear all expenses and
              third-party charges incurred by the GoGetter as evidenced by a
              valid receipt (“expenses”) and treat that the delivery, job, task
              or service or post had been done as per the original post. In the
              same way, the poster must pay the sums equal to the full job fee
              as per the time of the original posting to the GoGetter. In this
              event, the posting fee, expenses and full job fee shall be
              deducted from the Platform Credits of the poster by Eternal Meteor
              Sdn. Bhd. without further notice to the poster and any liability
              borne by us and the GoGetter unless the original post had stated
              that the full job fee and/or expenses is/are to be paid by him/her
              to the GoGetter by cash-on-hand. In such an event, an amount of
              Platform Credits equal to the full job fee and/or expenses shall
              be deducted from the account of the poster and temporarily held in
              the Platform until the full job fee and expenses are completely
              paid for by the poster to the GoGetter. If the poster fails to pay
              the full job fee and expenses to the GoGetter after a GoGetter has
              commenced with, carried out or completed a delivery, job, task or
              service or a post on the Platform, the Platform Credits that had
              been temporarily held in the Platform shall be immediately used to
              pay for the expenses and full job fee by Eternal Meteor Sdn. Bhd.
              without further notice to the poster and any liability borne by us
              and the GoGetter.
              <br /> <br />
              7. As a Gogetter, you agree to comply with and abide by the
              following Platform charges (“monetary penalties”) as per our
              Monetary Penalties Policy on the Platform.
              <br /> <br />
            </>
            <u>GoGet Recruit</u>
            <br />
            <>
              8. When you, as the poster, post an advertisement or job
              advertisement on the Platform to support your business, a separate
              fee will be charged by Eternal Meteor Sdn. Bhd (“advertisement
              fee”). Hence, you agree to pay Eternal Meteor Sdn. Bhd the
              advertisement fee and all relevant charges for your advertisement
              or post. Furthermore, all advertisement fees are fixed and
              non-refundable once a post is posted by you. The Platform and
              Eternal Meteor Sdn. Bhd. offer no hiring guarantee for flexible
              hiring posts that are posted by you as the poster.
              <br />
              <br />
            </>
            <u>General</u>
            <br />
            <>
              9. As a poster, you agree to assume full liability for the payment
              of all fees and costs due to the GoGetter and Eternal Meteor Sdn.
              Bhd. Additionally, as a GoGetter, you agree to assume full
              liability for the payment of all fees and costs due to the poster
              and Eternal Meteor Sdn. To this extent, Eternal Meteor Sdn. Bhd.
              is absolutely entitled to take all necessary action to seek
              compensation and relief from you and your representatives over any
              claims of injury, harm or damage sustained, the cost thereof shall
              be borne by you.
              <br />
              <br />
              10. The poster and GoGetter shall assume their own risks and
              responsibilities in connection with financial transactions arising
              from a post on the Platform. The Platform and Eternal Meteor Sdn.
              Bhd. shall not be held liable, responsible, blamed and harmed for
              any event of default on behalf of a poster and/or GoGetter. Events
              of default include but are not limited to non-payment of fees
              and/or expenses to the GoGetter, and/or non-completion of a
              delivery, job, task or service by a GoGetter for whatsoever
              reason.
              <br />
              <br />
            </>
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Restricted Activities</H1>
            <>
              1. You agree that you will use the services in a manner consistent
              with any and all applicable laws and regulations as well as any of
              our Platform terms, conditions, policies and updates.
              <br /> <br />
              2. With respect to your account, participation on the Platform or
              through the services, you agree that you will not:
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. Impersonate any person or entity;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. "stalk" or otherwise harass any person;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. Express or imply that any statements you make are endorsed by
                us, without our specific prior written consent;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. Use any robot, spider, site search/retrieval application, or
                other manual or automatic device or process to retrieve, index,
                "data mine", or in any way reproduce or circumvent the
                navigational structure or presentation of the services or its
                contents;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. Publish, distribute or reproduce in any way any copyrighted
                material, trademarks, or other proprietary information without
                obtaining the prior consent of the owner of such proprietary
                rights;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. Remove any copyright, trademark or other proprietary rights
                notices contained in the service;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                g. Interfere with or disrupt the services or the Platform or the
                servers or networks connected to the services or the Platform;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                h. Post, email or otherwise transmit any material that contains
                software viruses or any other computer code, files or programs
                designed to interrupt, destroy or limit the functionality of any
                computer software or hardware or telecommunications equipment;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                i. Forge headers or otherwise manipulate identifiers in order to
                disguise the origin of any information transmitted through the
                service;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                j. "frame" or "mirror" any part of the service, without our
                prior written authorization or use meta tags or code or other
                devices containing any reference to us or the services or the
                Platform in order to direct any person to any other website for
                any purpose; or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                k. Modify, adapt, sublicense, translate, sell, reverse engineer,
                decipher, decompile or otherwise disassemble any portion of the
                services or any software used on or for the services or cause
                others to do so.
              </div>
              <br />
              3. You further agree that your information and your interactions
              with Eternal Meteor Sdn. Bhd, on or through the Platform shall
              not:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. Be false, inaccurate or misleading (directly or by omission
                or failure to update information);
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. Infringe any third party's rights, including but not limited
                to: intellectual property rights, copyright, patent, trademark,
                trade secret or other proprietary rights or rights of publicity
                or privacy;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. Violate any law, statute, ordinance or regulation or do
                anything that becomes a restricted activity as per this terms of
                service;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. Be anything expressly or impliedly defamatory, trade
                libelous, abusive, obscene, profane, offensive, sexually
                oriented, threatening, harassing, racially offensive or illegal
                material;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. Contain any offensive anatomical or sexual references, or
                offensive sexually suggestive or connotative language;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. Contain any viruses, trojan horses, worms, time bombs,
                cancelbots, easter eggs or other computer programming routines
                that may damage, detrimentally interfere with, surreptitiously
                intercept or expropriate any system, data or personal
                information;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                g. Create liability for us or cause us to become subject to
                regulation as a delivery carrier or provider of courier service;
                or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                h. Link directly or indirectly to any other websites.
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                i. Allow any transfer, use, or sell your Platform account and/or
                id to any other party.
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                j. Cause or allow to cause any infringement of policies, terms
                of service, terms and conditions and written agreements of the
                Platform and Eternal Meteor Sdn. Bhd.
              </div>
              <br />
              We reserve the right, but we have no obligation, to reject or take
              action against any poster or GoGetter that does not comply with
              these prohibitions. Aside from our monetary penalties, we reserve
              the right, but are not obligated to investigate, report you to the
              authorities, inform the other users in the Platform regarding you,
              suspend and/or terminate your account and participation in the
              Platform at any time if you have/are/are being suspected of:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. misusing the Platform or the services; and
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. communicating, acting or behaving in a way which could be
                regarded by us third parties or other users as inappropriate or
                whose conduct is unlawful or illegal; and/or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. encouraging, promoting or inciting an activity, matter or job
                which could be regarded by us, third parties or other users as a
                scam/fraudulent activity and/or assisting in or connected to a
                scam/fraudulent activity.
              </div>
              <br />
              Furthermore, you waive your rights to make any claim against us
              for terminating your account and participation in the Platform as
              well as accept any conditions placed on you and/or necessary
              action taken against you for the purposes of protecting the
              interests of the Platform and our users.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Billing and Payment</H1>
            <>
              1. Partners, clients, customers, posters or GoGetters of the
              Platform may be expected to pay in advance for the services via
              the Platform or in cash. In the event that neither applies,
              advance future payments shall be completed by the posters via the
              designated method as prescribed in the Platform or Eternal Meteor
              Sdn. Bhd. invoices.
              <br /> <br />
              2. All other invoices issued through the Platform or by Eternal
              Meteor Sdn. Bhd. must be paid within 14 days of the date of
              invoice unless otherwise agreed in writing by the Eternal Meteor
              Sdn. Bhd. In the event of late payment, a late payment interest
              shall be automatically levied on the amount outstanding before and
              after judgment at the rate of 8% of the total invoice sum and
              shall be in force from the due date until the date of payment. In
              addition, invoices unpaid for more than 30 days after the invoice
              date will incur an automatic penalty surcharge of 10% of the
              outstanding amount (including the compounding interest).
              <br /> <br />
              3. If any amount of an invoice is disputed then the recipient
              shall inform Platform or Eternal Meteor Sdn. Bhd. in writing of
              the grounds for such dispute within 7 days of delivery of the
              goods, failing which acceptance is deemed by the issuer.
              <br /> <br />
              4. Platform and/or Eternal Meteor Sdn. Bhd. reserves the right to
              increase a quoted fee in the event that its GoGetter, poster,
              partner, customer or client requests a variation to the work
              agreed without any waiver to these terms and conditions.
              <br /> <br />
              Users of the Platform acknowledge that Platform is not party to
              any contracts for the jobs completed. Payment for the jobs is made
              directly from the Poster to the GoGetter and not by Platform
              <br /> <br />
              By using Platform you will be required to provide your bank
              account details and credit/debit card to Platform and to the
              Payment Service Provider utilized by Platform. Posters will be
              responsible for paying the invoice of each job which may include:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                1. Pricing of items agreed and provided by GoGetter.
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                2. Any out of pocket expenses made by the GoGetter but agreed by
                the Poster.
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                3. The tip/fee/gratuity payment for services rendered by the
                GoGetter.
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                4. The service charge Platform charges for utilization of the
                platform.
              </div>
              <br /> <br />
              <span>
                By agreeing to use Platform, you agree to abide by and comply
                with the terms of service of{' '}
                <Colored href="https://www.braintreepayments.com/legal/acceptable-use-policy">
                  Braintree's Acceptable Use Policy.
                </Colored>
              </span>
              <br />
              <br />
              <H3>1. Payment Terms</H3>
              <span>
                <b>1.1&nbsp;</b>These payment terms below constitute as a legal
                agreement between you and Eternal Meteor Sdn. Bhd., governing
                your access to and use of our Services as a paying customer. Any
                changes and updates made by us shall be binding on you.
              </span>
              <br /> <br />
              <H3>2. Changes of Terms</H3>
              <span>
                <b>2.1&nbsp;</b>We may amend the Payment Terms from time to time
                in our sole discretion without notice or liability to you. It is
                your responsibility to review these Payment Terms periodically.
                By continuing to use the Services following such amendments to
                the Payment Terms, you agree to be bound by such amendments. If
                you do not agree to the Payment Terms, now or at any time,
                please do not use the Site, Applications, Subscription Plans, or
                any of the Paid Services.
              </span>
              <br /> <br />
              <H3>3. Minimum Requirements</H3>
              <span>
                <b>3.1&nbsp;</b>To obtain or subscribe for Paid Services on or
                through Platform, you must provide certain registration
                information (more particularly set out in the Website Terms of
                Use) and obtain a Platform Limited account. You acknowledge that
                you are responsible for maintaining the security of, and
                restricting access to, your account and password, and you agree
                to accept responsibility for all purchases and other activities
                that occur under your account. Eternal Meteor Sdn. Bhd. sells
                its services only to those users who can legally make purchases
                with a credit card or other form of payment accepted by Eternal
                Meteor Sdn. Bhd.
              </span>
              <br /> <br />
              <H3>4. GoGet Commission Terms</H3>
              <b>4.1 Payment:</b>
              <br />
              1. By acting as a verified GoGetter, you authorize Eternal Meteor
              Sdn. Bhd to charge the applicable fees, referred to as “Platform
              Commission” to your designated billing payment method.
              <br /> <br />
              2. When you claim a job, you will be charged immediately.
              <br /> <br />
              3. You agree to abide by the terms and conditions of our payment
              collections partner. Our payments collections partner is BillPlz
              (https://www.billplz.com/terms.pdf)
              <br /> <br />
              4. If your account has a due amount, you must settle the balance
              owed within 7 days through our payments collections partner,
              failing which, Platform at its absolute discretioni has the right
              to suspend your account until such time as payment is made.
              <br /> <br />
              5. Account balances can be set off by incentives or admin fees.
              <br /> <br />
              6. Platform has full discretion in investigating and deeming
              fraudulent transactions, by which Platform has the ability to
              suspend your account.
              <br /> <br />
              7. As a GoGetter, you agree to pay Platform a commission for
              matching you with a job. The commission will be as per stated in
              the Platform.
              <br /> <br />
              8. In the event GST or any other ancillary charges are required to
              be added to the commission, Platform has the right at its absolute
              discretion to revise the rate of commission to accommodate this.
              <br /> <br /> <br />
              <b>4.2 Incentives:</b>
              <br />
              From time to time we may offer incentives for our GoGetters.
              Platform has full rights to determine the incentives as and when
              and may change them at any point in time. Platform can deem any
              sort of misinformation provided, abuse or misuse of the Platform;
              if so, Platform has full rights to suspend and take actions
              against the GoGetter or Poster. If the GoGetter has received any
              complaints, strikes, or reports that go against standard policy as
              stipulated in the verification session, Platform reserves the
              right to not provide the incentive. Upon any dispute on the
              incentive between Platform and GoGetter, Platform has the final
              decision on whether the GoGetter qualifies for the incentive.
              <br /> <br /> <br />
              <b>4.3 Fair usage:</b>
              <br />
              1. Platform has the right to withhold payment due to any GoGetter
              in connection to a case where there is a:
              <br />
              <br />
              <div style={{ paddingLeft: '1rem' }}>a. Platform Fee;</div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. Restricted Activities; and/or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>c. monetary penalty.</div>
              <br />
              Investigation will take place and Platform has the final decision
              on whether the payment should be withheld permanently or not. If
              Platform or its officers conclude from investigation that any of
              the above matters has occurred, Platform has the right to refuse
              or forfeit payment of a GoGetter at any time as well as make any
              compensation claim for any contract damage or harm occasioned.
              <br /> <br />
              2. You agree that Platform, in its sole discretion, with or
              without prior notice, may suspend or terminate your account for
              any reason, including, without limitation, if Platform believes
              that you have violated these Payment Terms, Platform Terms or the
              Website Terms of Use. Platform may also in its sole discretion,
              and at any time, modify or discontinue providing any service, or
              any part thereof, with or without notice. Additionally, you agree
              that Platform shall not be liable to you or any third-party for
              any interference with, suspension or termination of, your access
              to the account.
              <br /> <br />
              3. Any compensation refund will be granted based on the terms and
              procedure laid down in our “In- app compensation request feature”
              and subject to the discretion of the management of Eternal Meteor
              Sdn. Bhd. Refunds, however, will be determined based on
              investigation, information submitted by users and after we have
              concluded that the funds must be refunded to the user.
              <br /> <br />
              <H3>5. Price Adjustments</H3>
              <span>
                <b>5.1&nbsp;</b>We may increase the commission payable at any
                point in time. If you continue to use Platform, you shall be
                deemed to have accepted the new cost for that renewal term and
                any subsequent renewal terms.
              </span>
              <br /> <br />
              <H3>6. Accessing Our Services</H3>
              <span>
                <b>6.1 &nbsp;</b>We make every effort to ensure that our
                Services are always available, but we cannot guarantee that
                Services will not be uninterrupted. We will not be liable to you
                if for any reason our Services are unavailable at any time or
                for any period.
              </span>
              <br /> <br />
              <span>
                <b>6.2 &nbsp;</b>You are responsible for making all arrangements
                necessary for you to have access to our Services. You are also
                responsible for ensuring that all persons who access our
                Services through your internet connection are aware of these
                Payment Terms and other applicable terms and conditions, and
                that they comply with them.
              </span>
              <br /> <br />
              <H3>7. GoGet Credits </H3>
              <span>
                <b>7.1&nbsp;</b> You may purchase Platform Credits through the
                payment method offered on the platform or through a formal
                invoicing method issued by Eternal Meteor Sdn Bhd. <br /> <br />
                <b>7.2&nbsp;</b> Platform Credits are strictly non-refundable.{' '}
                <br /> <br />
                <b>7.3&nbsp;</b> You may not transfer, resell or exchange
                Platform Credits in any form to other Platform user accounts.
                <br /> <br />
                <b>7.4&nbsp;</b> If you purchase Platform Credits, you have
                agreed to the terms of service of the Company's processing
                partner and your financial institution. You will bear all fees
                that may be charged by such processing partners and/or your
                financial institution.
                <br /> <br />
                <b>7.5&nbsp;</b> If you purchase Platform Credits under a
                Platform Business Account, you have agreed to bear management
                fees. <br /> <br />
                <b>7.6&nbsp;</b> The Company may upon its sole discretion reject
                your request to purchase Platform Credits, for any reason
                whatsoever, including without limitation, where the Company
                deems your account to exercise fraudulent activity and motives.{' '}
                <br /> <br />
                <b>7.7&nbsp;</b> Your Platform Credits will not receive interest
                or other earnings. The Company may receive interest on amounts
                that the Company holds on your behalf. You agree to assign your
                rights to the Company for any interest derived from your
                Platform Credits.
                <br /> <br />
                <b>7.8&nbsp;</b> You may check your Platform Credits balance and
                expiration details in your account.
                <br /> <br />
                <b>7.9&nbsp;</b> If the event of any error in your Platform job
                that results in chargebacks from financial institutions,
                chargebacks for compensation to GoGetters due to misconduct, or
                other errors, the Company reserves the right to claw back the
                amount (up to the disputed amount) from your Platform Credits or
                by any other way it deems fit in its sole discretion.
                <br /> <br />
                <b>7.10&nbsp;</b> The Company reserves the right to forfeit your
                Platform Credits where upon investigation it believes that your
                account may be fraudulent, illegal, or involving any criminal
                activity or where the Company reasonably believes you are a
                threat to the safety of the Community.
                <br /> <br />
                <b>7.11&nbsp;</b> All Platform Credits will only be valid for
                one (1) year from the date of your last credit purchase or the
                posting date of your last completed job, whichever is later.{' '}
                <br /> <br />
                <b>7.12&nbsp;</b> You may view your expiration date in the
                Platform Application and it shall serve as conclusive evidence
                of the expiration date of your Platform Credits. The Company
                shall have the right to deal with the expired Platform Credits
                in such manner as it deems fit in its absolute discretion
                including such Platform Credits being forfeited.
              </span>
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Insurance</H1>
            <>
              1. At Platform, we are not, in any way, directly or indirectly
              liable and responsible for personal loss or injury, and property
              loss or damage experienced by you. All posters and GoGetters are
              responsible to obtain their insurance or protection against risk
              and shall not hold Platform and/or Eternal Meteor Sdn. Bhd. liable
              or responsible for damage or loss suffered. However, as a
              GoGetter, you can choose to purchase a daily or monthly insurance
              option with our Platform partner to protect you while you are
              doing GoGet jobs. Notwithstanding this, Platform and/or Eternal
              Meteor Sdn. Bhd. reserves the right to recover compensation or
              claim indemnity against you as the poster and/or GoGetter for
              direct or indirect damage or loss suffered.
              <br /> <br />
              2. To protect the smooth operations of the Platform, Eternal
              Meteor Sdn. Bhd. is insured against risk and loss directly caused
              by Platform employees and Eternal Meteor Sdn. Bhd.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Social Media and Networking Sites</H1>
            <>
              1. As part of the functionality of the Platform, you may be able
              to login through online accounts you may have with third party
              service providers (each such account, a “third party account”) by
              either providing your third party account login information
              through the Platform; or allowing Platform to access your third
              party account, as is permitted under the applicable terms and
              conditions that govern your use of each third party account.
              <br /> <br />
              2. You represent that the Platform is entitled to disclose your
              information in the Platform, social media and its networking
              sites, without breach by you of any of the terms and conditions
              that govern your use of the applicable third party account and
              without obligating Platform to pay any fees or making Platform
              subject to any usage limitations imposed by such third party
              service providers. By granting Platform access to any third party
              accounts, you understand that Platform may access, make available
              and store (if applicable) any content that you have provided to
              and stored in your third party account (the “social networking
              site/sns content”) so that it is available on and through the
              Platform via your account, including without limitation any
              friend, contacts or following/followed lists, and Platform may
              submit and receive additional information to your third party
              account as indicated herein.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Platform E-mail and Text Communications</H1>
            <>
              1. E-mail communications and text messages sent from or through
              the Platform or Eternal Meteor Sdn. Bhd. are designed to make your
              Platform experience more efficient. By becoming a poster, you
              specifically agree to accept and consent to receiving e-mail
              communications and text messages initiated from us or through us,
              which include, without limitation: message notification e-mails,
              e-mails or text messages informing you about potential available
              Platform tasks or posters and e-mails informing you of promotions
              we run and emails informing you of new and existing features we
              provide. Standard text messaging charges applied by your cell
              phone carrier will apply to text messages we send. If you change
              your mobile phone service provider, the notification service may
              be deactivated for your phone number and you may need to re-enroll
              in the notification service. Platform reserves the right to cancel
              the notification service at any time. If you do not wish to
              receive any of our e-mail communications or text messages, please
              do not use the services or sign up on the Platform.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Information Control</H1>
            <>
              1. Location data provided by the Platform is for basic location
              purposes only and is not intended to be relied upon in situations
              where precise location information is needed or where erroneous,
              inaccurate or incomplete location data may lead to death, personal
              injury, property or environmental damage. Neither Platform, nor
              any of its content providers, guarantees the availability,
              accuracy, completeness, reliability, or timeliness of location
              data displayed by the services. Any of your information, including
              geolocational data, you upload, provide, or post on the Platform
              may be accessible to certain spaces in the Platform. We cannot
              verify or guarantee the accuracy of the information a poster
              provides us on the Platform, and we do not control the information
              provided by other posters that is made available through our
              system. Therefore, Platform cannot and does not confirm each
              poster's purported identity. You may find other poster's
              information to be offensive, harmful, inaccurate, or deceptive.
              Please use caution and common sense when using the Platform.
              Please note that there are also risks of dealing with underage
              persons or people acting under false pretense. By using the
              Platform, you agree to accept such risks and Platform is not
              responsible for the acts or omissions of others on the Platform.
              In order to help you evaluate with whom you are dealing, Platform
              can link to a poster's facebook.com profile if they supply us with
              their facebook.com account information.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Proprietary Rights</H1>
            <>
              1. Eternal Meteor Sdn. Bhd. owns and retains ownership of the
              Platform, and all intellectual property therein. Contingent upon
              your compliance with the terms and conditions of this agreement,
              Platform hereby grants to you a limited, non-transferable,
              non-exclusive, non-assignable, revocable license to use the
              Platform on any desktop or mobile device that you own or control
              and as permitted by these Platform terms and conditions of
              service. This license does not allow you to use the Platform on
              any mobile device that you do not own or control, and you may not
              distribute or make the Platform available over a network where it
              could be used by multiple devices at the same time. You may not
              rent, lease, lend, sell, redistribute or sublicense the Platform.
              You may not copy (except as expressly permitted by this license
              and the usage rules), decompile, reverse engineer, disassemble,
              attempt to derive the source code of, modify, or create derivative
              works of the Platform, any updates, or any part thereof (except as
              and only to the extent any foregoing restriction is prohibited by
              applicable law). Any attempt to do so is a violation of the rights
              of Platform and its licensors. If you breach this restriction, you
              may be subject to prosecution and damages. The terms of the
              license will govern any upgrades provided by Platform that replace
              and/or supplement the Platform, unless such upgrade is accompanied
              by a separate license in which case the terms of that license will
              govern.
              <br /> <br />
              2. You warrant and represent to us that your information is posted
              by you and that you are the sole author of your information. To
              enable the Platform to use your information without violating any
              rights you might have in such information, you automatically
              grant, and you represent and warrant that you have the right to
              grant, to us and Eternal Meteor Sdn.Bhd., a non-exclusive,
              worldwide, perpetual, irrevocable, royalty-free, sub-licensable
              (through multiple tiers) right to exercise the copyright,
              publicity, and database rights you have in your information and
              your content, and to use, copy, perform, display and distribute
              such information and content and to prepare derivative works of,
              or incorporate into other works, such information and content, in
              any media now known or not currently known, with respect to your
              information. Platform will only use your information and content
              in accordance with our privacy policy. You may remove your content
              or your information from the Platform at any time. If you choose
              to remove your content or your information, the license granted
              above will automatically expire, however you acknowledge that
              Platform may retain archived copies of your content. Platform does
              not assert any ownership over your content; rather, as between us
              and you, subject to the rights granted to us in these terms of
              service, you retain full ownership of all of your content and any
              intellectual property rights or other proprietary rights
              associated with your content. In addition, other posters may post
              copyrighted information on the Platform, which has copyright
              protection whether or not it is identified as copyrighted. Except
              for that information which is in the public domain or for which
              you have been given permission, you will not copy, modify,
              publish, transmit, distribute, perform, display, or sell any such
              proprietary information on the Platform.
              <br /> <br />
              3. Eternal Meteor Sdn. Bhd. and the Platform respect the
              intellectual property of others, and expect posters to do the
              same. If you believe, in good faith, that any materials on the
              services infringe upon your copyrights, please send the following
              information to Platform vide the Platform:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. A description of the copyrighted work that you claim has been
                infringed, including specific location on the services where the
                material you claim is infringed is located. Include enough
                information to allow Platform to locate the material, and
                explain why you think an infringement has taken place; explain
                why you think an infringement has taken place;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. A description of the location where the original or an
                authorized copy of the copyrighted work exists - for example,
                the url (internet address) where it is posted or the name of the
                book in which it has been published;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. Your address, telephone number, and e-mail address;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. A statement by you that you have a good faith belief that the
                disputed use is not authorized by the copyright owner, its
                agent, or the law;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. A statement by you, made under penalty of perjury, that the
                information in your notice is accurate, and that you are the
                copyright owner or authorized to act on the copyright owner's
                behalf; and
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                f. An electronic or physical signature of the owner of the
                copyright or the person authorized to act on behalf of the owner
                of the copyright interest.
              </div>
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Notices, Complaints</H1>
            <>
              1. Except as explicitly stated otherwise, any notices to Platform
              shall be given by certified mail, postage prepaid and return
              receipt requested to:
              <b>
                Eternal Meteor Sdn Bhd <br />
                1 Level 16 No,
                <br />
                Wisma Uoa Damansara II,
                <br />
                Penthouse 16, 6, Changkat Semantan, Bukit Damansara,
                <br />
                50490 Kuala Lumpur
              </b>
              <br /> <br />
              2. Additionally, any notices to you shall be provided to you
              through the Platform or given to you via the email address you
              provide to Platform during the registration process. In such a
              case, notice shall be deemed given 3 days after the date that the
              email was sent. Notice shall be deemed given 24 hours after email
              is sent, unless the sending party is notified that the email
              address is invalid. Alternatively, we may give you notice by
              certified mail, postage prepaid and return receipt requested, to
              the address provided to Platform during the registration process.
              In such case, notice shall be deemed given 3 days after the date
              of mailing.
              <br /> <br />
              3. To resolve a complaint regarding the service, you should first
              contact our customer service department by email at
              contact@goget.my. If our customer service department is not able
              to resolve your complaint within 60 days from the date of the
              filing, you may write to us officially on the matter at the
              address above, failing a response within 14 days thereof, file a
              complaint with the Tribunal For Consumer Complaints Of Malaysia as
              stipulated in the Consumer Protection Act 1999.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Breach & Indemnity</H1>
            <>
              1. Without limiting other remedies, we may terminate your
              participation, remove your information, warn our community of your
              actions, issue a warning, and refuse to provide our services to
              you if:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. You breach this agreement or the documents it incorporates by
                reference;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. We are unable to verify or authenticate any information you
                provide to us;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. We believe that your actions may have caused financial,
                reputational and/or actual loss or legal liability to our
                posters, GoGetters and/or us, or the Platform; and/or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. If we suspect that you have engaged in scam, illegal,
                unlawful and/or fraudulent activity in connection with the
                Platform or the services.
              </div>
              <br /> <br />
              2. You will defend, indemnify, and hold us and our officers,
              directors, employees, agents and any third parties harmless for
              any losses, costs, liabilities and expenses (including reasonable
              attorneys' fees) relating to or arising out of your use of the
              service, including:
              <br /> <br />
              <div style={{ paddingLeft: '1rem' }}>
                a. Your breach of this agreement or the documents it
                incorporates by reference;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                b. Your violation of any law or the rights of a third party,
                including, without limitation, to the Platform, Eternal Meteor
                Sdn. Bhd. and our representatives and/or partners, our posters,
                our GoGetters, other motorists, and pedestrians, as a result of
                your own interaction with such third party,
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                c. Any allegation that any materials that you submit to us or
                transmit to the services or to us infringe or otherwise violate
                the copyright, trademark, trade secret or other intellectual
                property or other rights of any third party;
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                d. Your ownership, use or operation of a motor vehicle or
                passenger vehicle, including your provision of deliveries to
                posters; and/or
              </div>
              <br />
              <div style={{ paddingLeft: '1rem' }}>
                e. Any other activities in connection with the services. This
                indemnity shall be applicable without regard to the negligence
                of any party, including any indemnified person.
              </div>
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Online Content Disclaimer</H1>
            <>
              1. Opinions, advice, statements, offers, or other information or
              content made available through the services, but not directly by
              us, are those of their respective authors, and should not
              necessarily be relied upon. Such authors are solely responsible
              for such content. We do not guarantee the accuracy, completeness,
              or usefulness of any information on the services and neither do we
              adopt nor endorse nor are we responsible for the accuracy or
              reliability of any opinion, advice, or statement made by parties
              other than us. Under no circumstances will we be responsible for
              any loss or damage resulting from anyone's reliance on information
              or other content posted on the services, or transmitted to
              posters. We reserve the right, but we have no obligation, to
              monitor the materials posted in the public areas of the services.
              Notwithstanding this right, you remain solely responsible for the
              content of the photos, profiles (including your name, image, and
              likeness), messages, notes, text, information, music, video,
              advertisements, listings, and other content (the "content") that
              you post in the public areas of the services and in your private
              e-mail messages. We shall have the right to remove any such
              material that in our sole opinion violates, or is alleged to
              violate, the law or this agreement or which might be offensive,
              illegal, or that might violate the rights, harm, or threaten the
              safety of posters or others. E-mails sent between you and other
              posters that are not readily accessible to the general public will
              be treated by us as private to the extent required by applicable
              law.
              <br /> <br />
              2. The Platform contains (or you may be sent through the Platform
              or the services) links to other websites ("third party sites") as
              well as articles, photographs, text, graphics, pictures, designs,
              music, sound, video, information, applications, software and other
              content or items belonging to or originating from third parties
              (the "third party applications, software or content"). Such third
              party sites and third party applications, software or content are
              not investigated, monitored or checked for accuracy,
              appropriateness, or completeness by us, and we are not responsible
              for any third party sites accessed through the Platform or any
              third party applications, software or content posted on, available
              through or installed from the Platform, including the content,
              accuracy, offensiveness, opinions, reliability, privacy practices
              or other policies of or contained in the third party sites or the
              third party applications, software or content. Inclusion of,
              linking to or permitting the use or installation of any third
              party site or any third party applications, software or content
              does not imply approval or endorsement thereof by us. If you
              decide to leave the Platform and access the third party sites or
              to use or install any third party applications, software or
              content, you do so at your own risk and you should be aware that
              our terms and policies no longer govern. You should review the
              applicable terms and policies, including privacy and data
              gathering practices, of any web site to which you navigate from
              the Platform or relating to any applications you use or install
              from the Platform.
              <br /> <br />
              3. In the event that you have a dispute with one or more posters,
              you agree to release Platform and Eternal Meteor Sdn. Bhd. (and
              our officers, directors, agents, subsidiaries, joint ventures and
              employees) from claims, demands and damages (actual and
              consequential) of every kind and nature, known and unknown,
              suspected and unsuspected, disclosed and undisclosed, arising out
              of or in any way connected to such disputes with other posters or
              to your use of the Platform or the services. In other words, you
              are solely responsible for your interactions with other posters.
              We reserve the right, but have no obligation, to monitor disputes
              between you and other posters. Please carefully select the type of
              information that you post on the Platform or through the services
              or release to others. We disclaim all liability, regardless of the
              form of action, for the acts or omissions of other posters or
              posters (including unauthorized posters, or "hackers"). Platform
              only offers a venue that enables GoGetters and posters to match
              with each other. Platform does not offer delivery services and
              Platform is not a delivery company. We are not involved in the
              actual delivery provided by GoGetters to posters. As a result, we
              have no control over the quality or reliability of the delivery
              that occurs as a result of the service; nor do we have any control
              over the truth or accuracy of the posters' information listed on
              the Platform. We cannot ensure that a GoGetter or poster is who he
              or she claims to be or that a GoGetter or poster will actually
              complete an arranged service. We reserve the right to change any
              and all content, software and other items used or contained in the
              Platform and the services at any time without notice. Reference to
              any products, services, processes or other information, by trade
              name, trademark, manufacturer, supplier or otherwise does not
              constitute or imply endorsement, sponsorship or recommendation
              thereof, or any affiliation therewith, by Platform or the
              Platform.
              <br /> <br />
              4. The Platform and the services may be temporarily unavailable
              from time to time for maintenance or other reasons. Platform
              assumes no responsibility for any error, omission, interruption,
              deletion, defect, delay in operation or transmission,
              communications line failure, theft or destruction or unauthorized
              access to, or alteration of, poster communications. Platform is
              not responsible for any technical malfunction or other problems of
              any telephone network or service, computer systems, servers or
              providers, computer or mobile phone equipment, software, failure
              of email or players on account of technical problems or traffic
              congestion on the internet, on the Platform, on any web site or
              any combination thereof, including injury or damage to poster's or
              to any other person's computer, mobile phone, or other hardware or
              software, related to or resulting from using or downloading
              materials in connection with the web and/or in connection with the
              services.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Limitation of Liability</H1>
            <>
              1. We, the Platform and Eternal Meteor Sdn. Bhd., our
              subsidiaries, officers, directors, employees and our suppliers
              provide the Platform and the services on an "as is" basis and
              without any warranty or condition, express, implied or statutory.
              We do not guarantee and do not promise any specific results from
              use of the Platform and/or the services. We, our subsidiaries,
              officers, directors, employees and our suppliers specifically
              disclaim any implied warranties of title, merchantability, fitness
              for a particular purpose and non-infringement. We do not warrant
              that your use of the services will be accurate, complete,
              reliable, current, secure, uninterrupted, always available, or
              error-free, or will meet your requirements, that any defects in
              the services will be corrected, or that the services are free of
              viruses or other harmful components. We disclaim liability for,
              and no warranty is made with respect to, connectivity and
              availability. We cannot guarantee that each poster is at least the
              required minimum age, nor do we accept responsibility or liability
              for any content, communication or other use or access of the
              Platform or the services by persons under the age of 18 in
              violation of this agreement. We are not responsible or liable in
              any manner for any content posted on the Platform or in connection
              with the service, whether posted or caused by posters of the
              Platform, by Platform, by third parties or by any of the equipment
              or programming associated with or utilized in the Platform or the
              services. Although we provide rules for poster conduct and
              postings, we do not control and are not responsible for what
              posters post, transmit or share on the Platform and are not
              responsible for any offensive, inappropriate, obscene, unlawful or
              otherwise objectionable content and/or scam you may encounter on
              the Platform or in connection with any content. Platform is not
              responsible for the conduct, whether online or offline, of any
              poster of the Platform or services. It also is possible for others
              to obtain personal information about you due to your use of the
              Platform or the services, and that the recipient may use such
              information to harass or injure you. We are not responsible for
              the use of any personal information that you disclose on the
              Platform or through the services.
              <br /> <br />
              2. In no event will we, our subsidiaries, officers, directors,
              employees or our suppliers, be liable to you for any incidental,
              consequential, or direct damages (including, but not limited to,
              damages for deletion, corruption, loss of data, loss of programs,
              failure to store any information or other content maintained or
              transmitted by our services, service interruptions, or for the
              cost of procurement of substitute services) arising out of or in
              connection with Platform, our services or this agreement (however
              arising, including negligence) even if we or our agents or
              representatives know or have been advised of the possibility of
              such damages. We do not screen the tasks posted on the Platform
              using the services in any way. As a result, we will not be liable
              for any damages, direct, indirect, incidental and/or
              consequential, arising out of the use of Platform or the services,
              including, without limitation, to damages arising out of
              communicating and/or meeting with other posters of Platform or the
              services, or introduced to you via Platform or the services. Such
              damages include, without limitation, physical damages, bodily
              injury, death and or emotional distress and discomfort.
              Notwithstanding anything to the contrary contained herein, our
              liability, and the liability of our subsidiaries, officers,
              directors, employees, and suppliers, to you or any third parties
              in any circumstance is limited to up to 10% or the value of the
              good in question or RM 100.00, whichever is lower.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Confidentiality</H1>
            <>
              1. You agree not to use any technical, financial, strategic and
              other proprietary and confidential information relating to
              Platform's business, operations and properties (“confidential
              information”) disclosed to you by Platform for your own use or for
              any purpose other than as contemplated herein. You shall not
              disclose or permit disclosure of any confidential information to
              third parties. You agree to take all reasonable measures to
              protect the secrecy of and avoid disclosure or use of confidential
              information of Platform in order to prevent it from falling into
              the public domain. Notwithstanding the above, you shall not have
              liability to Platform with regard to any confidential information
              which you can prove: (a) was in the public domain at the time it
              was disclosed by Platform or has entered the public domain through
              no fault of yours; (b) was known to you, without restriction, at
              the time of disclosure, as demonstrated by files in existence at
              the time of disclosure; (c) is disclosed with the prior written
              approval of Platform; (d) becomes known to you, without
              restriction, from a source other than Platform without breach of
              this agreement by you and otherwise not in violation of Platform's
              rights;or (e) is disclosed pursuant to the order or requirement of
              a court, administrative agency, or other governmental body;
              provided, however, that you shall provide prompt notice of such
              court order or requirement to Platform to enable Platform to seek
              a protective order or otherwise prevent or restrict such
              disclosure.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Termination</H1>
            <>
              1. Either you or we may terminate your participation in the
              Platform by removing your information at any time, for any or no
              reason, without explanation, effective upon sending written or
              email notice to the other party. Upon such termination, we will
              remove all of your information from our servers, though we may
              retain an archived copy of records we have about you as required
              by law or for legitimate business purposes. We maintain sole
              discretion to bar your use of the services in the future, for any
              or no reason. Even after your participation in the Platform is
              terminated, you remain bound to the terms of confidentiality in
              this service term and condition.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <ActivityCard
          customStyle={{
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            borderRadius: '20px',
            marginTop: '53px'
          }}
        >
          <Container>
            <H1>Resolution of Disputes and Legal Claims</H1>
            <>
              1. Legal disputes or claims between the parties which cannot be
              resolved informally will be submitted to friendly negotiations
              first between their legal counsels before further submitting the
              same for mediation in Malaysia. Parties may elect mediation or
              court as the finality of the dispute for a binding decision. In
              the event of mediation, the mediation shall be conducted by the
              Malaysian Mediation Centre (“MMC”). Alternatively, any judgment on
              the award rendered by the courts may be entered in any court
              having jurisdiction thereof. You agree that in no event shall any
              claim, action or proceeding by you related in any way to the
              Platform and/or the services (including your use of the Platform
              and/or the services) be instituted more than 6 years after the
              cause of action arose.
            </>
            <br /> <br />
          </Container>
        </ActivityCard>
        <br /> <br />
        <br />
        <br />
        <br />
      </Layout>
    </>
  );
};

export default TermsConditions;
